import { FC } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import Img, { ImgProps } from "../components/Img"
import Link, { LinkProps } from "../components/Link"
import styled from "styled-components"
import chunk from "lodash/chunk"
import marked from "marked"
import PageHeader from "../components/PageHeader"
import Meta, { MetaProps } from "../components/Meta"
import VimeoPlayer from "../components/VimeoPlayer"
import LazyLoad from "react-lazyload"
import LazyLoadPlaceholder from "../components/LazyLoadPlaceholder"

const Logo = styled(Img)`
  transform: translateX(-50%);
  top: 25%;
  @media(max-width: 639px) {
    top: auto;
    left: auto;
    transform: none;
    position: static;
  }
`

const Nav = styled.nav`
  background-color: rgba(0, 12, 23, .6);
  @media(max-width: 639px) {
    background-color: rgba(5, 32, 51, 0.95);
  }
`

interface NavSectionProps {
  logoNav: boolean
}

const NavSection = styled.div`
  transition: background-color .3s linear;
  background-color: rgba(5, 32, 51, 0.95);
  &:hover {
    background-color: rgba(5, 32, 51, .25);
  }
  @media(max-width: 639px) {
    background-color: transparent;
    ${({ logoNav }: NavSectionProps) => (
      logoNav ? `order: -1;` : `order: 0;`
    )}
  }
`

interface Concept {
  backgroundColor: string,
  title: string,
  text: string,
  img: ImgProps,
  link?: LinkProps
}

interface EnvironmentPartner extends ImgProps {
  href: string
}

interface IndexPageTemplateProps {
  menu: {
    img: ImgProps,
    nav: LinkProps[],
    logo: ImgProps
  },

  video: {
    title: string,
    vimeoId: string,
  },

  intro: {
    title: string,
    text: string
  },

  portfolio: {
    img: ImgProps,
    title: string,
    text: string,
    link: LinkProps
  },

  environment: {
    partners: EnvironmentPartner[],
    img: ImgProps,
    title: string,
    text: string,
    link: LinkProps
  },

  structure: {
    img: ImgProps,
    title: string,
    text: string
  },

  partnership: {
    img: ImgProps,
    title: string,
    text: string,
    link: LinkProps
  },

  concepts: {
    title: string,
    text: string,
    concepts: Concept[]
  },

  career: {
    img: ImgProps,
    title?: string,
    text: string,
    link: LinkProps
  },

  employees: {
    img: ImgProps,
    title: string,
    text: string,
    link: LinkProps
  }
}

const IndexPageTemplate: FC<IndexPageTemplateProps> = ({
  menu,
  video,
  intro,
  portfolio,
  structure,
  partnership,
  concepts,
  career,
  employees,
  environment
}) => (
  <main id="index-page">
    <PageHeader />
    <section id="index-page-video" className="iframe-container">
      <div id="index-page-video-overlay"></div>
      <VimeoPlayer
        id="introduksjons-video"
        title={video.title}
        vimeoId={video.vimeoId}
        fullScreen={true}
        background={true}
        autoplay={true}
        loop={true}
        muted={true}
      />
      <div
        id="om-bonum"
      >
        <h1 className="title text-light">
          Eiendomsutvikler for bolig-<br/>og næringsprosjekter
        </h1>

        <hr className="sep ml-0 bg-white" />

        <div
          className="max-w-lg markdown md:max-w-none text-white"
          dangerouslySetInnerHTML={{
            __html: marked(intro.text)
          }}
        />

        <a className="-ml-1 p-2 pt-3 mt-8 btn-ghost border-current hover:border-white hover:bg-white hover:text-black tracking-normal normal-case font-secondary text-white" href="/ansatte">
          Kontakt oss
        </a>
      </div>
    </section>

    <section
      id="portefølje"
      className="box-section"
    >
      <div className="box-container">
        <div className="box-text">
          <h2 className="title">
            {portfolio.title}
          </h2>

          <hr className="sep" />

          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: marked(portfolio.text)
            }}
          />

          <Link
            to={portfolio.link.href}
            className="btn-ghost mt-12 xl:mt-8"
          >
            {portfolio.link.text}
          </Link>
        </div>
      </div>

      <div className="box-container">
        <Img
          className="box-img"
          src={portfolio.img.src}
          alt={portfolio.img.alt}
        />
      </div>
    </section>

    <section
      id="miljotiltak"
      className="box-section"
    >
      <div className="box-container">
        <Img
          className="box-img"
          src={environment.img.src}
          alt={environment.img.alt}
        />
      </div>

      <div className="box-container">
        <div className="box-text">
          <h2 className="title">
            {environment.title}
          </h2>

          <hr className="sep" />

          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: marked(environment.text)
            }}
          />

          <Link
            to={environment.link.href}
            className="btn-ghost my-12"
          >
            {environment.link.text}
          </Link>

          <div className="mx-auto w-full max-w-md flex items-center justify-between">
            {environment.partners.map(({
              src,
              alt,
              href
            }, index) => (
              <Link
                key={index}
                to={href}
                title={alt}
                className="hover:opacity-50"
              >
                <Img
                  className="w-20 lg:w-16 md:w-20 sm:w-16"
                  src={src}
                  alt={alt}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>

    <section
      id="selskapsstruktur"
      className="box-section"
    >
      <div className="box-container">
        <div className="box-text">
          <h2 className="title">
            {structure.title}
          </h2>

          <hr className="sep" />

          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: marked(structure.text)
            }}
          />
        </div>
      </div>

      <div className="box-container">
        <Img
          className="box-img"
          src={structure.img.src}
          alt={structure.img.alt}
        />
      </div>
    </section>

    <section
      id="partnerskap"
      className="box-section"
    >
      <div className="box-container">
        <Img
          className="box-img"
          src={partnership.img.src}
          alt={partnership.img.alt}
        />
      </div>

      <div className="box-container">
        <div className="box-text">
          <h2 className="title">
            {partnership.title}
          </h2>

          <hr className="sep" />

          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: marked(partnership.text)
            }}
          />

          <Link
            to={partnership.link.href}
            className="btn-ghost mt-12 xl:mt-8"
          >
            {partnership.link.text}
          </Link>
        </div>
      </div>
    </section>

    <section
      id="konsepter"
      className="box-section flex-wrap"
    >
      {concepts.concepts.map(({
        title,
        text,
        img,
        backgroundColor,
        link
      }, index) => (
        <div
          key={index}
          className="bg-cover bg-center w-full"
          style={{ backgroundImage: `url(${img.src})` }}
        >
          <div
            className="w-full h-full flex items-center"
            style={{ backgroundColor }}
          >
            <div className="box-text text-white">
              <h2 className="title">
                {title}
              </h2>

              <hr className="sep bg-white" />

              <div
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: marked(text)
                }}
              />

              {!!link && !!link.href && !!link.text ? (
                <Link
                  to={link.href}
                  className="btn-ghost light mt-8"
                >
                  {link.text}
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </section>

    <section
      id="karriere"
      className="box-section"
    >
      <div className="box-container bg-gray-100 p-6">
        <Img
          className="max-w-xl mx-auto w-full"
          src={career.img.src}
          alt={career.img.alt}
        />
      </div>

      <div className="box-container">
        <div className="box-text">
          <h2 className="title">
            {career.title}
          </h2>

          <hr className="sep" />

          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: marked(career.text)
            }}
          />

          <Link
            to={career.link.href}
            className="btn-ghost mt-12 xl:mt-8"
          >
            {career.link.text}
          </Link>
        </div>
      </div>
    </section>

    {/* <section
      id="ansatte"
      className="box-section"
    >
      <div className="box-container">
        <div className="box-text">
          <h2 className="title">
            {employees.title}
          </h2>

          <hr className="sep" />

          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: marked(employees.text)
            }}
          />

          <Link
            to={employees.link.href}
            className="btn-ghost mt-12 xl:mt-8"
          >
            {employees.link.text}
          </Link>
        </div>
      </div>

      <div className="box-container">
        <Img
          className="box-img"
          src={employees.img.src}
          alt={employees.img.alt}
        />
      </div>
    </section> */}
  </main>
)

interface IndexPageProps {
  data: {
    markdownRemark: {
      frontmatter: IndexPageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <IndexPageTemplate
        menu={frontmatter.menu}
        video={frontmatter.video}
        intro={frontmatter.intro}
        portfolio={frontmatter.portfolio}
        structure={frontmatter.structure}
        partnership={frontmatter.partnership}
        concepts={frontmatter.concepts}
        career={frontmatter.career}
        employees={frontmatter.employees}
        environment={frontmatter.environment}
      />
    </>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        meta {
          title
          description
          css
          js
        }

        menu {
          img {
            src
            alt
          }
          nav {
            text
            href
          }
          logo {
            src
            alt
          }
        }

        video {
          title
          vimeoId
        }

        intro {
          title
          text
        }

        portfolio {
          img {
            src
            alt
          }
          title
          text
          link {
            text
            href
          }
        }

        environment {
          partners {
            src
            alt
            href
          }
          img {
            src
            alt
          }
          title
          text
          link {
            href
            text
          }
        }

        structure {
          img {
            src
            alt
          }
          title
          text
        }

        partnership {
          img {
            src
            alt
          }
          title
          text
          link {
            text
            href
          }
        }

        concepts {
          title
          text
          concepts {
            backgroundColor
            title
            text
            img {
              src
              alt
            }
            link {
              href
              text
            }
          }
        }

        career {
          img {
            src
            alt
          }
          title
          text
          link {
            href
            text
          }
        }

        employees {
          img {
            src
            alt
          }
          title
          text
          link {
            href
            text
          }
        }

      }
    }
  }
`
